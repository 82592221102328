@import "_variables.scss";

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    padding: 5px;
}

.patient-status {
    /*display: block;*/
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}
.visit-container {
    display: block;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}
.visit-estimated {
    color: $dark-gray;
}
.visit-started {
    color: $color-blue;
}
.visit-completed {
    color: $color-green;
}
.visit-skipped {
    color: $color-orange;
}
.visit-failed {
    color: $color-red;
}
.visit-timeclosed {
    color: $color-azure;
}

.visit-tracking-table table > tbody > tr > td:nth-child(1),
.visit-tracking-table table > thead > tr:nth-child(1) > th:nth-child(1) {
    background-color: #f6f6f6c9;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
}

.visit-tracking-table table > tbody > tr > td:nth-child(2),
.visit-tracking-table table > thead > tr:nth-child(1) > th:nth-child(2) {
    background-color: #f6f6f6c9;
    position: sticky;
    position: -webkit-sticky;
    left: 150px;
    z-index: 1;
}

.visit-tracking-table table > tbody > tr > td:nth-child(3),
.visit-tracking-table table > thead > tr:nth-child(1) > th:nth-child(3) {
    background-color: #f6f6f6c9;
    position: sticky;
    position: -webkit-sticky;
    left: 300px;
    z-index: 1;
}