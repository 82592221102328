.card {
    border-radius: $border-radius-card;
    background-color: #ffffff;
    margin-bottom: 30px;
    opacity: 1;
    height: 100%;
    word-break: break-word;

    .header {
        padding: 15px 15px 0;
    }

    .header > h4 {
        margin: 0;
        font: normal normal normal 16px/25px Poppins;
    }

    .content {
        padding: 15px 15px 15px 15px;

        button {
            font: normal normal normal 16px/19px Montserrat;
        }
    }

    .avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        margin-right: 15px;
    }

    .footer {
        padding: 15px 15px 15px 15px;
        button {
            font: normal normal normal 16px/19px Montserrat;
        }
    }

    .card-text {
        color: #646464;
        font: normal normal normal 16px/25px Poppins;
    }
    .card-desc {
        font: normal normal normal 12px/18px Poppins;
        color: #7070706d;
        text-align: left;
    }
    .card-label {
        font: normal normal bold 16px/25px Poppins;
    }

    .info {
        margin-top: 20px;

        .card-text {
            color: #646464;
            font: normal normal normal 16px/25px Poppins;
        }

        .card-label {
            font: normal normal bold 16px/25px Poppins;
        }
    }

    legend {
        text-align: left;
        font: normal normal 900 22px/27px Montserrat;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 25px;
        margin-top: 30px;
    }
    .list-container {
        list-style: none;
        padding-left: 10px;

        .list-items {
            padding: 4px 0;

            button {
                padding: 0 1px;
            }
        }
    }
    p {
        word-break: break-word;
    }
}

.card-tabs {
    border-radius: 0 0 $border-radius-card $border-radius-card;
}

.card-shadow {
    box-shadow: 6px 6px 18px #00000029;
}

.card-actions {
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #646464;

    background: #dadcde 0% 0% no-repeat padding-box;
    border-radius: $border-radius-card;

    .content {
        .btn-full {
            margin-bottom: 10px;
        }
        .btn-full:last-child {
            margin-bottom: 15px;
        }
    }
}

.card-form {
    [class*="col-"] {
        margin-bottom: 15px;
    }
}

.card-notifications {
    .content {
        table > tbody > tr > td {
            text-align: left;
        }

        button {
            font: normal normal normal 24px/30px Montserrat;
        }
    }
}

.card-patient {
    text-align: left;
    font: normal normal bold 16px/25px Poppins;
    letter-spacing: 0px;
    //min-height: 215px;

    [class*="col-"] {
        margin-top: 20px;
    }

    .patient-avatar {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 127px;
            max-width: 127px;
            border-radius: 50%;
        }
    }

    .info {
        margin-top: 0;
        margin-right: 10px;
        .name {
            font-size: 24px;
            text-align: left;
            //padding-bottom: 10px;
        }
        .patient-text {
            color: #646464;
            font: normal normal normal 16px/25px Poppins;
        }
    }
}

.card-patient-horizontal {
    .patient-avatar {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 50px;
            max-width: 50px;
            border-radius: 50%;
        }
    }
    .group {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .icon {
        width: 55px;
        height: 54px;
        float: left;
        margin-right: 10px;
    }
    .title {
        text-align: left;
        font: normal normal bold 16px/19px Lato;
        color: #000000;
    }
    .description {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .svg-container {
        background-color: rgba(206, 206, 206, 0.23);
        width: 55px;
        height: 54px;

        svg {
            fill: $color-ichtys-green;
            width: 30px;
            height: 30px;
        }
    }
    .margin [class*="col-"] {
        margin: 10px 0;
    }
}

.card-patient-edit {
    text-align: left;
    letter-spacing: 0px;

    .patient-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .image {
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: 0.5s ease;
            backface-visibility: hidden;
        }
        .middle {
            transition: 0.5s ease;
            opacity: 0.3;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
        }

        &:hover .image {
            opacity: 0.3;
        }

        &:hover .middle {
            opacity: 1;
        }
        img {
            max-width: 250px;
            border-radius: 50%;
        }
    }
}

.card-evolution-text {
    min-height: 215px;
    textarea {
        font: normal normal 300 16px/24px Poppins;
    }
    .category {
        span {
            font: normal normal normal 16px/25px Poppins;
            text-align: left;
            letter-spacing: 0px;
            color: #646464;
            opacity: 1;
            vertical-align: super;
            padding-left: 10px;
        }
    }
}

.card-evolution {
    .content {
        > svg {
            height: 75px;
            width: 77px;
        }

        span {
            vertical-align: top;
            text-align: left;
            font: normal normal normal 18px/22px Montserrat;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }
    }
    .numbers {
        text-align: right;
        font: normal normal normal 60px/60px Montserrat;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
}

.card-personal-habits {
    min-height: 190px;
    [class*="col-"] {
        margin: 10px 0;
    }
    .group {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .icon {
        width: 55px;
        height: 54px;
        float: left;
        margin-right: 10px;
    }
    .title {
        text-align: left;
        font: normal normal bold 16px/19px Lato;
        color: #000000;
    }
    .description {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
}

.card-personal-contacts {
    min-height: 215px;
    [class*="col-"] {
        margin-top: 20px;
    }
    .group {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .icon {
        width: 55px;
        height: 54px;
        float: left;
        margin-right: 10px;
    }
    .title {
        text-align: left;
        font: normal normal bold 16px/19px Lato;
        color: #000000;
    }
    .description {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .svg-container {
        background-color: rgba(206, 206, 206, 0.23);
        width: 55px;
        height: 54px;

        svg {
            fill: $color-ichtys-green;
            width: 30px;
            height: 30px;
            margin: auto;
        }
    }
}

.card-medical-data {
    text-align: left;
    font: normal normal bold 16px/25px Poppins;
    letter-spacing: 0px;
    //min-height: 215px;

    .group {
        width: 134px;
        text-align: center;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
        margin-bottom: 10px;
    }
    .blood-box {
        background-color: rgba(206, 206, 206, 0.23);
        width: 134px;
        height: 132px;
        font: normal normal bold 56px/132px Lato;
        color: $color-ichtys-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-base;
    }

    .info {
        margin-top: 20px;

        .patient-text {
            color: #646464;
            font: normal normal normal 16px/25px Poppins;
        }
    }
}

.card-address-data {
    text-align: left;
    font: normal normal bold 16px/25px Poppins;
    letter-spacing: 0px;

    .address-box {
        background-color: rgba(206, 206, 206, 0.23);
        width: 100%;
        height: 100%;
        font: normal normal bold 56px/67px Lato;
        color: $color-ichtys-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-base;
    }

    .info {
        .patient-text {
            color: #646464;
            font: normal normal normal 16px/25px Poppins;
            margin-right: 10px;
        }
    }
}

.card-agenda {
    .header {
        background: transparent linear-gradient(270deg, #f5f7fc 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
        border-radius: $border-radius-card $border-radius-card 0px 0px;
        min-height: 71px;
    }
}

.card-daily-widget {
    .daily-agenda-row {
        td {
            text-align: left;
        }

        .daily-agenda-options {
            width: 140px;
            //display: none;
            text-align: center;
            font: normal normal normal 16px/19px Montserrat;

            svg {
                height: 27px;
                width: 30px;
                margin-right: 5px;
                cursor: pointer;
            }
        }

        //&:hover {
        //    .daily-agenda-options {
        //        display: block;
        //    }
        //}
    }
}

.card-protocol-info {
    .group {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .icon {
        width: 55px;
        height: 54px;
        float: left;
        margin-right: 10px;
    }
    .title {
        text-align: left;
        font: normal normal bold 16px/19px Lato;
        color: #000000;
    }
    .description {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .svg-container {
        background-color: rgba(206, 206, 206, 0.23);
        width: 55px;
        height: 54px;

        svg {
            fill: $color-ichtys-green;
            width: 30px;
            height: 30px;
        }
    }
}

.card-visitTracking-header {
    background-color: #eff1f3;
    letter-spacing: 0px;

    .content {
        padding: 5px 15px 5px 15px;

        .horizontal-menu {
            height: 50px;

            .menu-wrapper {
                min-height: 48px;
            }
        }

        .header-visit-container > a {
            text-align: left;
            font: normal normal normal 16px/19px Montserrat;
            color: #247dbd;
            padding: 13px 27px 11px 27px;
            display: block;
        }

        .header-visit-container.active > a {
            color: #20ad9b;
            background: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }

        .header-visit-container > a:focus,
        .header-visit-container > a:hover {
            text-decoration: none;
        }

        .scroll-menu-arrow > svg {
            font-size: 30px;
            color: #247dbd;
        }
        .scroll-menu-arrow--disabled > svg {
            color: #eff1f3;
        }
    }
}

.card-visit-tracking {
    .header {
        .title {
            text-align: left;
            font: normal normal 900 22px/27px Montserrat;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }
        .category {
            border-bottom: 1px solid #eff1f3;
            padding-bottom: 5px;
        }
    }
}

.card-expanded-content {
    .marginTop {
        margin-top: 10px;
    }
    .group {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .icon {
        width: 55px;
        height: 54px;
        float: left;
        margin-right: 10px;
    }
    .title {
        text-align: left;
        font: normal normal bold 16px/19px Lato;
        color: #000000;
    }
    .description {
        text-align: left;
        font: normal normal normal 14px/17px Lato;
        color: #b8b8b8;
    }
    .svg-container {
        background-color: rgba(206, 206, 206, 0.23);
        width: 55px;
        height: 54px;

        svg {
            fill: $color-ichtys-green;
            width: 30px;
            height: 30px;
            margin: auto;
        }
    }
}

.card-icon-data {
    text-align: left;
    font: normal normal bold 16px/25px Poppins-Bold;
    letter-spacing: 0px;

    .icon-box {
        background-color: rgba(206, 206, 206, 0.23);
        width: 100%;
        height: 100%;
        font: normal normal bold 56px/67px Lato;
        color: $color-ichtys-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-base;
    }

    .info {
        .patient-text {
            color: #646464;
            font: normal normal normal 16px/25px Poppins;
            margin-right: 10px;
        }
    }

    .statistics {
        td {
            font: normal normal bold 30px/46px Poppins;
            letter-spacing: 0px;
            opacity: 1;
        }
        .goal {
            color: $color-ichtys-blue;
        }
        .scrn {
            color: $color-ichtys-green;
        }
        .fail {
            color: $color-red;
        }
        .default {
            color: $color-black;
        }
    }
}

/* Responsive */

@media screen and (min-width: 768px) and (max-width: 1366px) {
    .card-medical-data {
        .blood-box {
            width: 120px;
            height: 120px;
            font: normal normal bold 53px/132px Lato;
        }
        .group {
            width: 120px;
        }
    }
}
