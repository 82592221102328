/*
=============== 
Variables
===============
*/

:root {
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --clr-grey-special: #eae6eb;
    --clr-red-special: #b4345c;
    --radius: 0.25rem;
}

.expandable-content {
    background-color: #ffffff;
    border: 2px solid var(--clr-grey-special);
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border-radius: var(--radius);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    box-shadow: var(--light-shadow);
    padding: 15px 15px;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        h4 {
            margin: 0;
            font: normal normal normal 16px/25px Poppins;
        }

        .btn {
            background: transparent;
            border-color: transparent;
            width: 2rem;
            height: 2rem;
            background: #eae6eb;
            background: var(--clr-grey-special);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #b4345c;
            color: var(--clr-red-special);
            margin-left: 1rem;
            align-self: center;
            min-width: 2rem;
        }

        svg {
            vertical-align: middle;
        }
    }
}

.ellipsis-text {
    cursor: pointer;
}
.LinesEllipsis-ellipsis {
    color: #259;
}
.LinesEllipsis--clamped {
    cursor: pointer;
}

/*Quito animacion al expandir. */
.react-bootstrap-table .row-expand-slide-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: none !important;
}
.react-bootstrap-table .row-expand-slide-appear-active {
    max-height: 1000px;
    transition: none !important;
}