.field-container {
    padding: 2px 2px;
    width: 100%;
}
.field-container:hover {
    border: 2px solid cornflowerblue;
    //padding: 10px 0;
}
    .field-container:hover .field-container-options {
        display: block;
        position: relative;
        float: right;
        top: -34px;
        z-index: 5;
    }
.field-container-options {
    display: none;
}
.field-container-options .btn {
    margin: 0 5px;
    padding: 2px 6px;
}
.field-container-options i {
    font-size: 20px;
}


.sortable-field-list {
    position: relative;
    z-index: 0;
    
    border: 1px solid #EFEFEF;
    border-radius: 3px;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
    background-color: #F3F3F3;
    outline: none;
}
.sortable-field-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
//    height: 40px;
    padding: 0 20px;
    background-color: #FFF;
    //border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}
.SortableHelper {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
    position: relative;
    top: 1px;
    display: block;
    width: 18px;
    height: 11px;
    opacity: .25;
    margin-right: 20px;
    cursor: row-resize;
    background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)
}
