.modal-backdrop.in {
    opacity: 0.37;
    border: 1px solid #707070;
    background: #000000 0% 0% no-repeat padding-box;
}
.modal-content {
    border-radius: $border-radius-modal;
    box-shadow: none;
    border: none;

    .modal-header {
        border-top-left-radius: $border-radius-modal;
        border-top-right-radius: $border-radius-modal;
        height: 58px;
        background: #e3e3e3 0% 0% no-repeat padding-box;

        .modal-title {
            text-align: left;
            font: normal normal normal 21px/31px Poppins;
            color: #646464;
            opacity: 1;
            display: inline-block;
        }
        .close {
            margin-top: 0;
            font-size: 32px;
            font-weight: bolder;
            color: #646464;
            opacity: 1;
        }
        > button > svg {
            transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
            margin: 0 auto;
        }
    }

    .modal-body {
        .modal-create-entry {
            text-align: center;
            font: normal normal normal 16px/19px Montserrat;
            letter-spacing: 0px;
            color: #636466;
            opacity: 1;

            .create-entry-container {
                margin: 20px 0;

                .title-create-entry {
                    width: 98px;
                    margin: 0 auto;
                    margin-top: 10px;
                }
                .btn-create-entry {
                    height: 98px;
                    width: 98px;
                    text-align: center;
                    > svg {
                        height: 44px;
                        width: 49px;
                        margin: 0;
                    }
                }
            }
            .select-protocol {
                margin-top: 20px;
            }
        }
    }
}

.tooltip-inner {
    max-width: 600px;
}
