.attachments-container {
  margin: 30px 93px;

  .row {
    margin-top: 30px;
    height: 25px;
    text-align: left;
    font: normal normal 400 16px/21px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
  }
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px dashed #7070706d;
    border-radius: 13px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    height: 191px;

    .dropzone-content {
      margin-top: 25px;
      text-align: center;
    }

    p {
      text-align: left;
      font: normal normal bold 16px/25px Poppins;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 10px;
    }
  }

  .dropzone:focus {
    border-color: #2196f3;
  }

  .dropzone.disabled {
    opacity: 0.6;
  }

  .file-icon {
    width: 8%;
    float: left;
  }
  .file-title {
    color: #247dbd;
    vertical-align: middle;
    line-height: 36px;
  }
  .file-size {
    color: #8f8f8f;
  }
}

.fileInput {
  margin-top: 15px;
  cursor: pointer;
}
.imgPreview {
  text-align: center;
  height: 135px;
  width: 135px;
}
.imgPreview img {
  width: 100%;
  height: 100%;
}
.previewText {
  width: 100%;
  margin-top: 20px;
}
.centerText {
  text-align: center;
}
.uploaded {
  /*width: 375px;*/
  margin: 10px;
  border: 1px solid #e7eaec;
  background-color: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .file-extension {
    padding: 15px;
    font-size: 40px;
    color: #0c3214;
  }
}

.file {
  display: flex;
  flex-direction: column;
}
.file-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  /* width: 300px;*/
  line-height: 0;
  color: #0c3214;
  font-size: 18px;
  letter-spacing: 1.5px;
}

.attachments-container-brief {
  margin-right: 15px; 

  .file-icon {
    margin-right: 10px;
    color: #e3e3e3;
    width: 22px;
    height: 29px;
  }

  .file-title {
    font: normal normal 400 16px/21px Segoe UI;
    color: #247dbd;
    vertical-align: middle;
    line-height: 36px;
  }
}
