.pointer {
    cursor: pointer;
}

.selected > td {
    color: #2480BB !important;
}

.panel-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.panel-header-data:not(:last-child) > span {
    font-size: 16px;
    margin-right: 1.3rem;
}
.panel-header:not(:last-child) > a {
    justify-self: end;
    text-align: end;
}
