.form-control::-moz-placeholder{
    @include placeholder($medium-gray,1);
 }
 .form-control:-moz-placeholder{
    @include placeholder($medium-gray,1);
 }
 .form-control::-webkit-input-placeholder{
    @include placeholder($medium-gray,1);
 }
 .form-control:-ms-input-placeholder{
    @include placeholder($medium-gray,1);
 }
 
 .form-control {
     background-color: $white-bg;
     //border: 1px solid $light-gray;
     border-radius: $border-radius-base;
     color: #565656;
     @include input-size($padding-base-vertical, $padding-base-horizontal - 4, $height-base);
     @include box-shadow(none);
 
     &:focus{
            background-color: $white-bg;
            border: 1px solid $color-ichtys-blue;
            @include box-shadow(none);
            outline: 0 !important;
            color: #333333;
     }
 
     .has-error &,
     .has-error &:focus,
     .has-success &,
     .has-warning &,
     .has-success &:focus,
     .has-warning &:focus{
         border-color: $light-gray;
         @include box-shadow(none);
     }
 
     .has-success &:focus,
     &.valid:focus{
         border-color: $success-color;
     }
 
     .has-error &,
     &.error,
     .has-error &:focus{
         color: $danger-color;
         border-color: $danger-color;
     }
 
     & + .form-control-feedback{
         border-radius: $border-radius-large;
         font-size: $font-size-base;
         margin-top: -16px;
         position: absolute;
         right: 10px;
         top: 50%;
         vertical-align: middle;
     }
 
     /*.open &{
         border-radius: $border-radius-base $border-radius-base 0 0;
         border-bottom-color: transparent;
     }*/
 }
 .input-sm{
     height: 30px;
     padding: $padding-small-vertical $padding-small-horizontal;
 }
 .input-lg{
     height: 55px;
     padding: $padding-large-vertical $padding-large-horizontal;
 }
 
 .has-error{
     .form-control-feedback{
         color: $danger-color;
     }
 }
 .has-success{
     .form-control-feedback{
         color: $success-color
     }
 }
 
 .control-label{
     .has-success &{
         color: $success-color;
     }
 
     .has-warning &{
         color: $warning-color;
     }
 
     .has-error &{
         color: $danger-color;
     }
 }
 
 .input-group-addon {
     background-color: $color-ichtys-blue;
     //border: 1px solid $light-gray;
     border-radius: $border-radius-base;

     svg {
         width: 22px;
         height: 22px;
     }
 
     .has-success &,
     .has-error &{
         background-color: $color-ichtys-blue;
         border: 1px solid $light-gray;
     }
 
     .has-error &,
     .has-error.input-group-focus &{
         border-color: $danger-color;
         color: $danger-color;
     }
 
     .has-success.input-group-focus &{
         border-color: $success-color;
     }
     .has-error .form-control:focus + &{
         border-color: $danger-color;
         color: $danger-color;
     }
     .has-success .form-control:focus + &{
         border-color: $success-color;
         color: $success-color;
     }
     .form-control:focus + &,
     .form-control:focus ~ &{
         background-color: $color-ichtys-blue;
         border-color: $color-ichtys-blue;
     }
 }
 
 .input-group .form-control:first-child,
 .input-group-addon:first-child,
 .input-group-btn:first-child > .dropdown-toggle,
 .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
     border-right: 0 none;
 }
 .input-group .form-control:last-child,
 .input-group-addon:last-child,
 .input-group-btn:last-child > .dropdown-toggle,
 .input-group-btn:first-child > .btn:not(:first-child) {
     border-left: 0 none;
 }
 .form-control[disabled],
 .form-control[readonly],
 fieldset[disabled] .form-control {
     background-color: $smoke-bg;
     color: $default-color;
     cursor: not-allowed;
 }
 
 .input-group-btn .btn{
     border-width: $border-thin;
     //padding: $padding-round-vertical $padding-base-horizontal;     
 }
 .input-group-btn .btn-default:not(.btn-fill){
     border-color: $medium-gray;
 }
 
 .input-group-btn:last-child > .btn{
     margin-left: 0;
 }
 
 .input-group-focus .input-group-addon{
     border-color: $medium-dark-gray;
 }

 .input-group .form-control {
    position: inherit;
    z-index: 1;
 }
