.table {
    .radio,
    .checkbox {
        position: relative;
        height: 20px;
        display: block;
        width: 20px;
        padding: 0px 0px;
        margin: 0px 5px;
        text-align: center;

        .icons {
            left: 5px;
        }
    }
    > tbody > tr {
        background: #ffffff 0% 0% no-repeat padding-box;
    }
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
        padding: 12px 8px;
        vertical-align: middle;
        text-align: center;
    }
    > thead > tr > th {
        padding: 0;
        text-transform: uppercase;
        font-weight: $font-weight-normal;
        text-align: center;
        font: normal normal normal $font-size-base/18px Montserrat;
        letter-spacing: 0px;
        color: #b8b8b8;
    }

    .td-actions .btn {
        @include opacity(0.36);

        &.btn-xs {
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    .td-actions {
        min-width: 90px;
    }

    > tbody > tr {
        position: relative;

        &:hover {
            .td-actions .btn {
                @include opacity(1);
            }
        }
    }
    .table-action-simple {
        max-width: 100px;
    }
    .header-view-btn {
        width: 140px;
    }
    .row-view-btn {
        text-align: center;
    }
    .header-view-entry-btn {
        width: 40px;
    }
    .header-swap-btn {
        width: 90px;
    }

    .header-date {
        max-width: 105px;
    }

    .table-view-btn {
        margin-right: 10px;
        display: inline;
    }
    .table-edit-btn {
        display: inline;
    }
    .table-date {
        text-align: center;
    }

    .status-inactive {
        color: $color-red;
    }
    .status-active {
        color: $color-green;
    }

    .header-protocol-number {
        width: 70px;
    }
    .header-actions {
        width: 130px;
    }
    .row-actions {
        width: 130px;
    }

    .item-data {
        .item-text {
            color: #646464;
            font: normal normal normal 14px/18px Poppins;
        }
        .item-label {
            font: normal normal bold 14px/18px Poppins;
        }
    }
}
.react-bootstrap-table th[data-row-selection] {
    width: 10px !important;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #ffffff;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background: #f6f6f6c9 0% 0% no-repeat padding-box;
}

.table-spaced {
    border-collapse: separate;
    border-spacing: 0 10px;

    > thead > tr > th {
        padding: 0;
        text-transform: uppercase;
        font-weight: $font-weight-normal;
        text-align: center;
        font: normal normal normal $font-size-base/18px Montserrat;
        letter-spacing: 0px;
        color: #b8b8b8;
        border-bottom: none;
    }

    > tbody > tr > td {
        text-align: center;
        font-size: 14px;
        height: 70px;
        border-top: 1px solid #cececed6;
        border-bottom: 1px solid #cececed6;
        font: normal normal normal 16px/19px Montserrat;
        letter-spacing: 0px;
        color: #636466;
    }
    > tbody > tr > td:first-child {
        border-left: 1px solid #cececed6;
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
    }
    > tbody > tr.draft > td:first-child {
        border-left: 12px solid $color-ichtys-blue;
    }
    > tbody > tr.published > td:first-child {
        border-left: 12px solid $color-ichtys-green;
    }
    > tbody > tr > td:last-child {
        border-right: 1px solid #cececed6;
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
    }

    svg {
        //fill: $color-ichtys-blue;
        height: 27px;
        width: 29px;
        margin-right: 20px;
        vertical-align: middle;
    }
}

.table-spaced-small {
    > tbody > tr > td {
        height: 51px;
        padding: 0;
    }

    svg {
        height: 27px;
        width: 27px;
        margin-right: 4px;
        //stroke: $color-ichtys-blue;
    }
}

.table-spaced-smallest > tbody > tr > td {
    height: 30px;
  }

.table-responsive {
    overflow: auto !important;
    overflow-x: auto !important;
}

.table-expanded {
    background-color: rgba(32, 173, 155, 0.19) !important ;
}
.parent-table-expanded {
    background-color: rgba(32, 173, 155, 0.39) !important;
}

.table-row-header {
    padding: 0;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    font: normal normal normal $font-size-base/18px Montserrat;
    letter-spacing: 0px;
    color: #b8b8b8;
    border-bottom: 2px solid #ddd;
}

.table-row-body {
    border-bottom: 2px solid #ddd;

    > div {
        padding: 12px 8px;
        vertical-align: middle;
        text-align: center;
    }
}
