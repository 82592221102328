@import "_variables.scss";

.error-container {
    min-height: 400px;

    h1 {
        margin-bottom: 20px;
        color: $danger-color;
        text-align: center;
        font: normal normal 800 90px/99px Montserrat;
        font-weight: 800;
    }
    h2 {
        color: $color-blue;
        text-align: center;
        font-size: 30px;
        font: normal normal 800 30px/39px Montserrat;
        text-transform: uppercase;
    }
    p {
        color: $black-color;
        text-align: center;
        font: normal normal normal 22px/27px Montserrat;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
    img {
        margin: 0 auto;
        display: block;
        height: 250px;
    }
}
