@import "_variables.scss";

.dashboard {
    letter-spacing: 0px;
    opacity: 1;

    .card {
        margin-bottom: 0;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buttons-widget {
        a {
            font: normal normal normal 18px/27px Poppins;
            margin-top: 15px;
        }
        .new-appointment-widget {
            box-sizing: border-box;
            background: $light-green;
            height: 242px;
            max-width: 279px;
        }
        .new-patient-widget {
            box-sizing: border-box;
            background: $light-blue;
            height: 242px;
            max-width: 279px;
        }
    }

    .datetime-widget {
        .date {
            font: normal normal normal 22px/28px Poppins;
            color: #247dbd;
            text-align: right;
        }
        .time {
            font: normal normal normal 56px/87px Poppins;
            color: #247dbd;
            text-align: right;
        }
    }

    .welcome-widget {
        height: 100%;
        display: flex;
        flex-direction: column;

        .welcome {
            font: normal normal normal 56px/87px Poppins;
            color: #247dbd;
            text-align: left;

            > span {
                font-weight: 900;
            }
        }

        .notifications {
            text-align: left;
            font: normal normal normal 20px/30px Poppins;
            color: #20ad9b;
        }
    }

    .search-widget {
        height: 100%;
        display: flex;
        flex-direction: column;

        .search-box {
            letter-spacing: 0px;
            opacity: 1;
            margin-top: auto;

            .form-group {
                margin-bottom: 0;
            }
            .form-control:focus {
                border: 1px solid #ccc;
            }

            .input-group-addon {
                background-color: #ffffff;
                color: #cecece;
                box-shadow: 6px 6px 18px #00000029;
                border-top-left-radius: 9px;
                border-bottom-left-radius: 9px;

                > svg {
                    width: 40px;
                    height: 30px;
                }
            }
            input {
                font: normal normal normal 18px/22px Lato;
                box-shadow: 6px 6px 18px #00000029;
                border-top-right-radius: 9px;
                border-bottom-right-radius: 9px;
                border-left: none;
                height: 55px;
            }
            mark {
                //color: #000;
                color: #fff;
                background: $color-ichtys-blue;
            }

            .dropdown-menu > li > a {
                font: normal normal normal 18px/22px Lato;
            }
            .dropdown-menu > .active > a {
                background-color: $color-ichtys-green;
            }

            .rbt-highlight-text {
                //background-color: #ff0;
                background: $color-ichtys-blue;
                font-weight: bold;
            }
        }
    }
}
