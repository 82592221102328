.nav-tabs{
    border: none;
    li{
        padding-right: 0;        
    }
    li>a {
        position: relative;
        display: block;

        padding: 18px 12px; 
        height: 50px;         
        border: none;
        //border-radius: $border-radius-tabs $border-radius-tabs 0 0;
        border-radius: 0;
        
        text-align: left;
        font: normal normal bold 16px/19px Montserrat;
        letter-spacing: 0px;
        color: #A6A6A6;;
        background-color: $color-ichtys-gray;
    }

    li.active>a, li.active>a:focus, li.active>a:hover {
        font: normal normal bold 16px/19px Montserrat;
        color: #000;
        cursor: default;
        background-color: #fff;
        border: 1px solid transparent;
        border-bottom-color: transparent;
    }
}