.btn {
  border-width: $border-thin;
  background-color: $transparent-bg;
  font-weight: $font-weight-normal;
  font-size: 16px;
  line-height: 19px;
  white-space: normal !important;
  text-align: left;

  @include opacity(0.8);
  padding: $padding-base-vertical $padding-base-horizontal;

  @include btn-styles($default-color, $default-states-color);

  border-width: $border-thin;
  border-radius: $btn-round-radius ;//!important;

  &.btn-icon {
    padding: $padding-round-vertical;
  }

  &:hover,
  &:focus {
    @include opacity(1);
    outline: 0 !important;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
  }

  .btn-label {
    .fa {
      width: 13px;
    }
  }

  .fa {
    width: 20px;
  }
  > svg {
    margin-right: 5px;
    height: 20px;
    width: 20px;
    vertical-align: text-bottom;
  }
}

// Apply the mixin to the buttons
.btn-default {
  @include btn-styles($default-color, $default-states-color);
}
.btn-primary {
  @include btn-styles($primary-color, $primary-states-color);
}
.btn-success {
  @include btn-styles($success-color, $success-states-color);
}
.btn-info {
  @include btn-styles($info-color, $info-states-color);
}
.btn-warning {
  @include btn-styles($warning-color, $warning-states-color);
}
.btn-danger {
  @include btn-styles($danger-color, $danger-states-color);
}
.btn-audit {
  @include btn-styles($audit-color, $audit-states-color);
}

.btn-neutral {
  @include btn-styles($white-color, $white-color);

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $white-color;
    color: $default-color;
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus {
    color: $default-color;
  }

  &.btn-fill:hover,
  &.btn-fill:focus {
    @include opacity(0.86);
  }

  &.btn-simple:active,
  &.btn-simple.active {
    background-color: transparent;
  }
}

.btn {
  &:disabled,
  &[disabled],
  &.disabled {
    @include opacity(0.5);
  }
}
.btn-simple {
  border: $none;
  font-size: $font-size-medium;
  padding: $padding-base-vertical $padding-base-horizontal;

  &.btn-icon {
    padding: $padding-base-vertical;
  }
}
.btn-lg {
  @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
  font-weight: $font-weight-normal;
}
.btn-sm {
  @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
.btn-xs {
  @include btn-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $border-radius-small);
}
.btn-wd {
  min-width: 140px;
}

.btn-full {
  width: 100%;
}
.btn-right {
  float: right;
}
.btn-margin {
  margin: 10px 10px;
}
.btn-margin-horizontal {
  margin: 0 20px 0 20px;
}
.btn-margin-bottom {
  margin-bottom: 30px;
}
.btn-group.select {
  width: 100%;
}
.btn-group.select .btn {
  text-align: left;
}
.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}
.btn-group > .btn {
  opacity: 1;
  &:not(:first-child):not(.dropdown-toggle) {
    border-left: none;
  }
}

.btn-action {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: $color-blue;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  -webkit-appearance: none;
  padding-right: 5px;
  background: 0 0;
  border: 0;
}

.btn-action:hover {
  color: $light-blue;
}

.svg-container {
  background-color: rgba(32, 173, 155, 0.19);
  width: 77px;
  height: 75px;
  border-radius: 9px;
  display: flex;

  > svg {
    opacity: 1;
    margin: auto;

    g {
      opacity: 1;
    }
    path {
      opacity: 1;
    }
  }
}
