
/* Skeleton class */
.skeleton-box {
    width: 100%;
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
  }
  .skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(20%, rgba(255, 255, 255, 0.2)), color-stop(60%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 1s infinite;
            animation: shimmer 1s infinite;
    content: "";
  }
  @-webkit-keyframes shimmer {
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }
  @keyframes shimmer {
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
  }