.react-select {
    
    .custom-select__menu {
        z-index: 2060 !important;
    }
    .custom-select__control,
    .practitioner-select__control {
        border-radius: $border-radius-base;
    }
    .Select-menu-outer {
        z-index: 999 !important;
    }
}

