@font-face {
    font-family: "Montserrat";
    src: url("../../fonts/Montserrat-Regular.woff?d7yf1v") format("truetype");
}
@font-face {
    font-family: "Montserrat";
    src: url("../../fonts/Montserrat-Bold.woff?d7yf1y") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato-Regular.woff") format("truetype");
}
@font-face {
    font-family: "Lato";
    src: url("../../fonts/Lato-Bold.woff") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-Regular.woff") format("truetype");
}
@font-face {
    font-family: "Poppins-Bold";
    src: url("../../fonts/Poppins-Bold.woff") format("truetype");
    font-weight: bold;
}

.w100 {
    @include font-weight(thin);
}
.w200 {
    @include font-weight(extra-light);
}
.w300 {
    @include font-weight(light);
}
.w400 {
    @include font-weight(book);
}
.w500 {
    @include font-weight(medium);
}
.w600 {
    @include font-weight(semi-bold);
}
.w700 {
    @include font-weight(bold);
}
.w800 {
    @include font-weight(extra-bold);
}
.w900 {
    @include font-weight(ultra-bold);
}

.italic {
    font-style:italic;
}
