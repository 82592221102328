.info-container {
    padding: 1rem .75rem;
    border: 1px solid #cececed6;
    border-radius: 4px;
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-around;
}

.info-title,
.info-container>div>.info-label,
.info-container>div>.info-text {
    font: normal normal bold 14px / 19px Poppins;
    text-align: left;
    letter-spacing: 0px;
    font-weight: bolder;
}

.info-title {
    font-size: 1.2rem;
    color: #646464;
    margin-bottom: 1rem;
}
.info-title.info-title-lg {
    font-size: 1.5rem!important;
}

.info-container>div>.info-label {
    color: #333;
}

.info-container>div>.info-text {
    color: #646464;
}