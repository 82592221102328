.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  overflow-x: hidden;
  // overflow-y: scroll;

  .logo-img {
    width: 100%;

    img {
      width: 64px;
      //height: 45px;
      display: block;
      margin: auto;
    }
  }

  .sidebar-wrapper {
    overflow: auto;
    position: relative;
    z-index: 4;
    width: $sidebar-width;
    height: calc(100% - 71px);
    padding-bottom: 30px;
    box-shadow: inset -1px 0px 0px 0px #cfcfca;

    > .nav {
      margin-top: 20px;

      svg {
        width: 52px;
        height: 36px;
      }
    }

    .dropdown .dropdown-backdrop {
      display: none !important;
    }
    .nav,
    .user .info {
      li {
        a {
          outline: none;
          cursor: pointer;
        }
      }

      ul > li > a {
        .sidebar-normal {
          margin: 0;
          position: relative;
          @include transform-translate-x(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
        }

        .sidebar-mini {
          text-transform: uppercase;
          float: left;
          width: 30px;
          text-align: center;
          margin-right: 15px;
          letter-spacing: 1px;
        }

        i {
          font-size: 17px;
          line-height: 30px;
          width: 26px;
        }
      }
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    transition: all 0.25s ease-out 0s;
  }

  .logo {
    padding: 12px 0px;
    margin: 0;
    box-shadow: inset -1px 0px 0px 0px #cfcfca;
    position: relative;
    z-index: 4;
    display: block;

    a.logo-mini {
      float: left;
      width: 30px;
      text-align: center;
      margin-left: 23px;
      margin-right: 15px;
      position: relative;
    }

    a.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: inline-block;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      line-height: 30px;
      white-space: nowrap;
      color: $white-color;
    }

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      line-height: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 15px;
      width: calc(100% - 30px);
      height: 1px;
    }
  }
  .nav {
    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }

    li {
      margin: 40px 0 40px 0;
      > a {
        padding-left: 10px;
        padding-right: 10px;
        //margin: 5px 15px 0px;
        border-radius: $border-radius-base;
        color: #ffffffad;
        font: normal normal normal 14px/17px Lato;

        .item-name {
          margin-top: 5px;
        }
      }
      a > svg {
        fill: #b8b8b8;
      }

      &:hover > a {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
        color: #ffffff;
      }

      &.active > a {
        color: #ffffff;
        opacity: 1;
        background: rgba(255, 255, 255, 0.23);
      }
      &.active a > svg {
        fill: #ffffff;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      position: relative;
      color: $white-color;
      @include transform-translate-x(0px);
      display: block;
      height: auto;
      opacity: 1;

      .caret {
        top: 12px;
        position: absolute;
        right: 0px;
      }
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }

  .logo-mini {
    display: none;
  }
}

.sidebar,
.bootstrap-navbar {
  .logo {
    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: Arial, sans-serif;
    }

    .logo-text {
      text-transform: uppercase;
      padding: 5px 0px 5px 15px;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      font-weight: $font-weight-normal;
      line-height: 30px;
      // text-align: center;
      white-space: nowrap;
      overflow: hidden;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0px;
      right: 15px;
      width: calc(100% - 30px);
      height: 1px;
      //background-color: rgba($white-color, 0.3);
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    opacity: 0.33;
    background: #000000;
  }

  &:after {
    @include line-gradient($color-ichtys-blue, $color-ichtys-green);
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }

  // colors for the sidebar with an image
  &[data-color="blue"]:after {
    @include line-gradient($color-blue, rgba($blue-color-bottom, 0.7));
  }
  &[data-color="azure"]:after {
    @include line-gradient($color-azure, rgba($azure-color-bottom, 0.7));
  }
  &[data-color="green"]:after {
    @include line-gradient($color-green, rgba($green-color-bottom, 0.7));
  }
  &[data-color="orange"]:after {
    @include line-gradient($color-orange, rgba($orange-color-bottom, 0.7));
  }
  &[data-color="red"]:after {
    @include line-gradient($color-red, rgba($red-color-bottom, 0.7));
  }
  &[data-color="purple"]:after {
    @include line-gradient($color-purple, rgba($purple-color-bottom, 0.7));
  }
}

.main-panel {
  position: relative;
  // z-index: 2; fix for Modals inside .main-panel
  float: right;
  width: $main-width;
  min-height: 100%;

  .main-content {
    //padding: 30px 15px;
    padding-top: 0;
    min-height: calc(100vh - 100px);
  }

  > .content-no-padding {
    padding: 0;
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 30px;
    height: $navbar-height;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper,
.navbar-fixed {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.sidebar,
.main-panel {
  max-height: 100%;
  height: 100%;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

.sidebar-mini {
  .visible-on-sidebar-regular {
    display: none !important;
  }
  .visible-on-sidebar-mini {
    display: inline-block !important;
  }
}

@media (min-width: $screen-md) {
  .sidebar-mini {
    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      font-weight: 200;
      z-index: 3;

      .logo {
        a.logo-mini {
          opacity: 1;
        }

        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav ul > li > a .sidebar-normal,
        .user .info ul > li > a .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0px);
        }
      }

      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav ul > li > a .sidebar-normal,
        .user .info ul > li > a .sidebar-normal,
        .user .info > a > span {
          @include transform-translate-x(0px);
          opacity: 1;
        }

        > .nav {
          li.active {
            > a:not([data-toggle="collapse"]) {
              &:before,
              &:after {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Breadcrumb
.breadcrumb-container {
  top: 0px;
  position: fixed;

  .breadcrumb {
    padding: 15px 0px;
    margin-bottom: 0;
    background: none;
  }
}

