.btn-create-entity {
  width: 155px;
  height: 90px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.btn-remove {
  color: $color-red !important;
}

.btn-remove:hover {
  color: $light-red !important;
}

.btn-table-actions {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: $color-blue;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  -webkit-appearance: none;
  padding-right: 5px;
  background: 0 0;
  border: 0;
}

.btn-table-actions:hover {
  color: $light-blue;
}
.btn-table-actions:focus {
  outline: none !important;
}

.btn-swap {
  float: left;
}

.btn-external {
  border-color: $warning-color;
  color: $warning-color;
}
.btn-protocol {
  border-color: $info-color;
  color: $info-color;
}
.btn-project {
  border-color: $success-color;
  color: $success-color;
}

.label-external {
  background-color: $warning-color;
  font-size: 100%;
}
.label-protocol {
  background-color: $info-color;
  font-size: 100%;
}
.label-project {
  background-color: $success-color;
  font-size: 100%;
}

.entry-item {
  border-top: 1px solid #cececed6;
  border-bottom: 1px solid #cececed6;
  border-right: 1px solid #cececed6;
  font: normal normal normal 14px/16px Montserrat;
  border-radius: 9px;
  margin-bottom: 20px;
  border-spacing: 0 2px;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  [class*="col-md-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .entry-summary {
    margin-top: 15px;
    font: normal normal normal 14px/16px Montserrat;
    line-height: 1.5;
    word-break: break-word;

    p {
      font-size: 14px;
      margin: 0;
    }
  }
  
  .label-condition {
    background-color: rgba(32, 173, 155, 0.19);
    color: rgb(32, 173, 155);
    font-size: 90%;
  }
}

.evolution-items {
  border-top: 1px solid #cececed6;
  border-bottom: 1px solid #cececed6;
  border-right: 1px solid #cececed6;
  font: normal normal normal 14px/16px Montserrat;
  border-radius: 9px;
  margin-bottom: 20px;
  border-spacing: 0 2px;
  max-height: 600px;
  overflow: auto;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  [class*="col-md-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .evolution-item {
    margin-top: 5px;
    font: normal normal normal 14px/16px Montserrat;
    line-height: 1.5;
    word-break: break-word;
  
    p {
      font-size: 14px;
      margin: 0;
    }
  }
}

.event-item {
  border: 1px solid #cececed6;
  font: normal normal normal 14px/16px Montserrat;
  border-radius: 9px;
  margin-bottom: 20px;
  border-spacing: 0 2px;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  [class*="col-md-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .event-summary {
    margin-top: 15px;
    font: normal normal normal 14px/16px Montserrat;
    line-height: 1.5;
    word-break: break-word;

    p {
      font-size: 14px;
      margin: 0;
    }
  }
}
.draft {
  border-left: 6px solid $color-ichtys-blue;
}
.published {
  border-left: 6px solid $color-ichtys-green;
}

.sticky {
  height: auto; position: sticky; top: 0px;
}

.condition {
  margin-top: 10px;
    
  .label-condition {
    display: inline-block;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: rgba(32, 173, 155, 0.19);
    color: rgb(32, 173, 155);
    font-size: 100%;
  }

  .btn-label-audit {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    vertical-align: middle;
    color: rgb(32, 173, 155);
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
    -webkit-appearance: none;
    padding-right: 5px;
    background: 0 0;
    border: 0;
}
}