@import "_variables.scss";

.rbc-header {
  //margin: auto;
  text-align: center;
  vertical-align: middle;
  font: normal normal bold 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
  border-bottom: none;

  a {
    font-weight: bold;
    line-height: 64px;
  }
}

// Oculto sección AllDay
.rbc-time-view .rbc-allday-cell {
  display: none;
}
/*****************************/
.rbc-time-view {
  .rbc-row {
    background: #f5f7fc 0% 0% no-repeat padding-box;
    border: 1px solid #7070700a;
    height: 64px;
  }
}
/*****************************/
.rbc-day-slot .rbc-event {
  border: none;
}

.rbc-event {
  padding: 1px 2px;
  position: relative;
  display: block;
  border-radius: 0;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 18px #00000029;

  text-align: left;
  font: normal normal bold 16px/19px Lato;
  letter-spacing: 0px;
  color: #646464;
  opacity: 1;

  border-left: 4px solid $color-blue;

  &.rbc-event-blue {
    border-left: 4px solid $color-blue;
  }
  &.rbc-event-green {
    border-left: 4px solid $color-green;
  }
  &.rbc-event-yellow {
    border-left: 4px solid $color-yellow;
  }
  &.rbc-event-orange {
    border-left: 4px solid $color-orange;
  }
  &.rbc-event-red {
    border-left: 4px solid $color-red;
  }
  &.rbc-event-black {
    border-left: 4px solid $color-black;
  }
  &.rbc-event-purple {
    border-left: 4px solid $color-purple;
  }
  &.rbc-event-gray {
    border-left: 4px solid $color-gray;
  }
  &.rbc-event-default {
    border-left: 4px solid $color-azure;
  }
  &.rbc-event-lock {
    border-left: 4px solid $color-black;
    background-color: $light-gray;
  }
  &.rbc-event-practitioner-lock {
    border-left: 4px solid $color-black;
    background-color: $light-orange;
  }

  .event-professional {
    font: normal normal normal 12px/15px Lato;
  }
}

.rbc-month-view {
  height: 500px;
}
.rbc-toolbar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
  text-align: center;
  margin-bottom: 1em;
}
.rbc-toolbar .rbc-toolbar-label {
  font: normal normal bold 34px/42px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 10px;
}
.rbc-time-header-cell-single-day {
  display: initial;
}

.rbc-toolbar button {
  border: none;
  padding: 8px 16px;
  text-align: center;
  font: normal normal bold 18px/22px Montserrat !important;
  letter-spacing: 0px;
  color: #979797;
  background: #f5f7fc 0% 0% no-repeat padding-box;
  border-radius: 6px;
}
.rbc-toolbar button:focus {
  outline: none;
}
.rbc-toolbar button.rbc-active {
  &,
  &:focus,
  &:hover {
    text-align: center;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: $color-ichtys-blue;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 6px 6px 18px #00000029;
    border-radius: 5px;
    opacity: 1;
  }
}
.rbc-toolbar button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 0;
  height: 2px;
  background-color: $color-ichtys-green;
  pointer-events: none;
}

.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: " ";
  background-color: $color-ichtys-green;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.rbc-event.rbc-selected {
  background-color: #888;
}
.rbc-timeslot-group {
  min-height: 85px;
}
.rbc-day-slot .rbc-time-slot {
  min-height: 21px;
}
.rbc-row-content {
  z-index: 0;
}
.rbc-event-confirmed {
  color: $success-states-color;
  font-weight: bolder;
  font-size: 16px;
}

.rbc-time-slot {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .rbc-label {
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
    width: 159px;
    text-align: center;
  }
}

.rbc-time-header-gutter {
  text-align: center;
  font: normal normal normal 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  background: $color-ichtys-blue 0% 0% no-repeat padding-box;
  border: 1px solid $color-ichtys-blue;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 6px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 2px 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 10px;
}

.rbc-agenda-table {
  th {
    background: #f5f7fc 0% 0% no-repeat padding-box;
    border: 1px solid #ddd;
    height: 64px;
    opacity: 1;
  }
}
